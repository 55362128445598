import React from 'react'
import { useRef, useState, useEffect } from "react";

const remoteEndpoint = process.env.REACT_APP_API_HOST;

function NavBar(props) {
  const navRef = props.navRef
  const submitHook = props.submitHook
  return (
    <>
      <div className="navbar bg-base-100">
        <div className="flex-1">
          <button className="btn btn-ghost normal-case text-xl">VM Uncovered</button>
        </div>
        <div className="flex-none gap-2">
          <div className="form-control">
            <form onSubmit={submitHook}>
              <input ref={navRef} type="text" placeholder="Search" className="input input-md input-bordered input-neutral" />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

function ResultTemplate(props) {
  const result = props.result ? props.result.data : null;
  const numberOnly = /^\d+$/;

  let wines = null;
  let title = null;

  let bodyCard = (
    <>
      <p>
        Turn Shiraz 2020 - <span className="italic">Black Market Deal #43034 </span>
        into <span className="italic">Haselgrove Tusail Shiraz 2020</span>
      </p>
      <p>Take the guesswork out of buying from Vinomofo's "Black Market" wines.</p>
      <p>Paste the product page URL or offer id (eg 43034) into the search box above to identify the real product name.</p>
      {/* <TopSearches /> */}
    </>
  )

  if (!result) {
    return <CardTemplate body={bodyCard} />
  } else if ('errors' in result) {
    title = "Sorry..."
    bodyCard = <p>
      I couldn't find a match for {result.errors.offerId}.
      {
        numberOnly.test(result.errors.offerId) ?
          <span className='font-medium'> Try the product URL instead.</span> :
          " Try something else."
      }
    </p>
  } else {
    const url = 'https://www.vinomofo.com.au' + result.route.path
    if (result.sku.wines.length > 1) {
      title = <a href={url} className="link link-hover" rel="noreferrer" target="_blank"> {result.name} </a>
      wines = result.sku.wines.map((wine) => <p>{wine.originalName}</p>)
    } else {
      title = <a href={url} className="link link-hover" rel="noreferrer" target="_blank"> {result.sku.wines[0].originalName} </a>
    }
    bodyCard = (
      <>
        {wines}
        <p> Vintage <span className="font-semibold">{result.vintage}</span> </p>
        <p> Bottle Size <span className="font-semibold">{result.variant}</span> </p>
        <p> Varietal <span className="font-semibold">{result.varietal}</span> </p>
        <p> Region <span className="font-semibold">{result.region}, {result.country}</span> </p>
        <p> Closure <span className="font-semibold">{result.closure}</span> </p>
        <p className="italic">{result.shortDescription}</p>
      </>
    )
  }

  return <CardTemplate body={bodyCard} title={title} />
}

function CardTemplate(props) {
  const title = props.title ? props.title : 'VM Uncovered'
  return (
    <>
      <div className="card lg:card-side bg-base-200 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">{title}</h2>
          {props.body}
        </div>
      </div>
    </>
  )
}

function PageBody() {
  const [result, setResult] = useState(() => { return null })
  const searchTerm = useRef(null);

  const handleSubmit = event => {
    const offerId = searchTerm.current.value;

    // Plausible search tracking
    window.plausible('Search', { props: { 'offerId': offerId } });

    if (offerId) {
      const url = `${remoteEndpoint}/api/vm/offer?offer_id=${encodeURIComponent(offerId)}`
      console.log(url)
      // fetch(remoteEndpoint+offerId.toString(),
      fetch(url,
        { mode: 'cors' }
      ).then(async (response) => {
        if (!response.ok) {
          throw new Error("Not 2xx response", { cause: response })
        }
        let data = await response.json();
        setResult(data);
      })
        // .then((data) => {
        //   console.log(data);
        //   setResult(data);
        // })
        .catch((error) => {
          console.log(error.toString());
          // Display some message to the user
          const errObj = {
            data: {
              errors: {
                'offerId': offerId
              }
            }
          };
          setResult(errObj)
        })
    } else {
      setResult(null);
    }
    event.preventDefault();
  }

  return (
    <>
      <NavBar navRef={searchTerm} submitHook={handleSubmit} />
      <ResultTemplate result={result} />
    </>
  )
}

function TopSearches() {
  const [searches, setSearches] = useState(() => { return null });

  useEffect(() => {
    const url = remoteEndpoint + '/api/vm/searches';
    fetch(url, { mode: 'cors' })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Unable to get top searches", { cause: response })
        } else {
          let data = await response.json();
          setSearches(data);
        }
      })
      .catch((error) => {
        console.log(error.toString());
      })
  }, [])

  if (!searches) {
    return
  }
  return (
    <div>
      <div className='divider'></div>
      <h1 className='font-bold'>Top Searches For This Week</h1>
      <ul>
        {searches.map((wine) => <li key={wine.offerid}>{wine.name} ({wine.offerid})</li>)}
      </ul>
    </div>
  )
}

function VinomofoPage() {
  return (
    <>
      <div className="container mx-auto space-y-2">
        <PageBody />
      </div>
    </>
  )
}

export default VinomofoPage;
