import './index.css';
import VinomofoPage from './pages/vinomofo';

function App() {
  return (
    <div className="App">
      <VinomofoPage />
    </div>
  );
}

export default App;
